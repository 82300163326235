<template>
    <div>
        <Modal
            title="產險批次編輯"
            v-model="isShowModal"
            width="640px"
            :class="{ uploading: stepSeq === 2 }"
        >
            <template v-slot:modal-content>
                <!-- editor-content -->
                <div v-if="stepSeq === 1">
                    <p class="second-title">編輯產險保單</p>
                    <p class="depiction">
                        請選擇「生效日」區間後下載Excel，依照格式批次編輯產險保單欄位並上傳。
                    </p>
                    <div class="filter-container">
                        <RadioButtons
                            :options="filterRadioOptions"
                            :disabled="isDownloading"
                            layout="column"
                            v-model="filterRadioValue"
                        >
                            <template v-slot:effectDateInterval>
                                <div class="effect-date-inputs">
                                    <Textbox
                                        name="effectDateStart"
                                        type="date"
                                        :disabled="isDownloading"
                                        v-model="effectDateStartValue"
                                    />
                                    ~
                                    <Textbox
                                        name="effectDateEnd"
                                        type="date"
                                        :disabled="isDownloading"
                                        v-model="effectDateEndValue"
                                    />
                                </div>
                            </template>
                        </RadioButtons>
                    </div>
                    <div class="download-container">
                        <div class="file-selection">
                            <template v-if="!isDownloading">
                                <Button
                                    iconClass="download-excel-btn"
                                    buttonWord="產險保單下載"
                                    buttonSize="default"
                                    buttonStyle="blue-hollow"
                                    @click="getExcelUrl()"
                                />
                            </template>
                            <Button
                                v-else
                                :width="144"
                                buttonWord="下載檔案中..."
                                iconClass="process-loading-icon"
                                buttonSize="default"
                                buttonStyle="hollow"
                                class="download-loading disabled"
                            />
                            <p class="has-file">
                                此檔案共有{{ insPickedCount }}筆產險保單
                                <span v-if="isNoPicked"
                                    >*下載需包含1筆以上的保單</span
                                >
                            </p>
                        </div>

                        <div
                            class="remark-container"
                            v-html="downloadRemarks"
                        />
                    </div>
                    <div class="upload-container">
                        <div class="upload">
                            <div class="file-selection">
                                <Button
                                    iconClass="upload-btn"
                                    buttonWord="選擇上傳檔案"
                                    buttonSize="default"
                                    buttonStyle="blue-hollow"
                                    @click="clickUploadInput()"
                                />
                                <input
                                    accept=".xlsx"
                                    id="selectedLocalFile"
                                    type="file"
                                    name="selectedLocalFile"
                                    @change="selectedLocalFile()"
                                    hidden
                                />
                                <p :class="{ 'has-file': localFile }">
                                    {{ fileName }}
                                    <span v-if="uploadError"
                                        >*{{ uploadError }}</span
                                    >
                                </p>
                            </div>
                            <Button
                                buttonWord="上傳"
                                :buttonStyle="localFile ? 'blue' : 'grey'"
                                :disabled="!localFile"
                                @click="uploadFile()"
                            />
                        </div>

                        <div class="remark-container" v-html="uploadRemarks" />
                    </div>
                </div>
                <!-- loading-content -->
                <div v-else-if="stepSeq === 2">
                    <div class="loading-container">
                        <div class="process-loading-icon" />
                        <div class="loading-message">
                            檔案上傳中，請耐心等候...
                            <transition name="fade">
                                <div v-if="isShowWaiteMessage">
                                    <p class="waiting-tip">
                                        系統處理檔案需花費些時間，您可以另開分頁繼續使用BMS系統，感謝您的等候！
                                    </p>
                                    <p class="waiting-tip warning">
                                        請注意！若離開或重整此頁面，將失去您目前的下載進度！
                                    </p>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
                <!-- result-content -->
                <div v-else-if="stepSeq === 3">
                    <p
                        class="result-status"
                        v-text="resultMessageList.result"
                        :class="isUploadSuccess ? 'success' : 'fail'"
                    />
                    <div class="status-description" v-if="isUploadSuccess">
                        <p>您的產險件保單編輯結果如下：</p>
                        <p>
                            已上傳檔名：<span>{{
                                resultMessageList.fileName
                            }}</span>
                        </p>
                        <p>
                            編輯成功數：
                            {{ resultMessageList.validCount }} &nbsp;筆
                        </p>
                        <p v-if="resultMessageList.invalidCount > 0">
                            編輯失敗數：&nbsp;

                            <span class="warning">
                                {{ resultMessageList.invalidCount }}
                            </span>
                            &nbsp; 筆
                        </p>
                    </div>
                    <p class="status-description" v-else>
                        原因:{{ resultMessageList.displayMsg }}
                    </p>

                    <div
                        v-if="resultMessageList?.invalidCount > 0"
                        class="error-container"
                    >
                        <p>
                            編輯失敗的保單資訊將不會被更新
                            <br />請將下方錯誤訊息的保單，在excel檔中重新編輯後再次上傳
                        </p>

                        <div class="error-message-container">
                            <div
                                class="error-message"
                                v-for="(
                                    error, index
                                ) in resultMessageList.invalidData"
                                :key="index"
                            >
                                <p class="wrong-row">
                                    Excel錯誤列數：{{ error.invalidRow }}
                                </p>
                                <p>保單號碼：{{ error.insNo }}</p>
                                <p
                                    v-for="(
                                        column, columnIndex
                                    ) in error.invalidColumns"
                                    :key="columnIndex"
                                >
                                    錯誤欄位/原因：{{
                                        (column.name === null
                                            ? '不明'
                                            : column.name) +
                                        '/' +
                                        column.description
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:modal-bottom v-if="stepSeq !== 2">
                <p
                    v-if="stepSeq === 1 && Object.keys(uploadResult).length > 0"
                    class="result-record"
                    @click="
                        ;(stepSeq = 3),
                            $setGaEvent('goResultPage', 'click-Button')
                    "
                >
                    查看匯入編輯結果
                </p>
                <Button
                    v-if="stepSeq === 3"
                    buttonWord="返回批次編輯"
                    buttonStyle="blue"
                    @click="
                        ;(stepSeq = 1),
                            $setGaEvent('goFilePage', 'click-Button')
                    "
                />
                <Button
                    buttonWord="關閉"
                    buttonStyle="grey"
                    @click="turnOffModal()"
                />
            </template>
        </Modal>

        <!-- 下載等候提醒跳窗 -->
        <Modal
            v-model="isShowWaiteMessageModal"
            title="正取得您的檔案中，請耐心等候"
            width="560px"
        >
            <template v-slot:modal-content>
                <p class="waiting-tip">
                    系統處理檔案需花費些時間，您可以另開分頁繼續使用BMS系統，感謝您的等候！
                </p>
                <p class="waiting-tip warning">
                    請注意！若離開或重整此頁面，將失去您目前的下載進度！
                </p>
            </template>
            <template v-slot:modal-bottom>
                <Button
                    buttonWord="我知道了"
                    buttonStyle="blue"
                    @click="this.isShowWaiteMessageModal = false"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import _ from 'lodash'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import RadioButtons from '@/components/RadioButtons.vue'
import Textbox from '@/components/Textbox.vue'
import {
    downloadPropertyExcelAPI,
    uploadPropertyExcelAPI
} from '@/assets/javascripts/api'

export default {
    name: 'InsuranceExcelEditingModal',
    emits: ['update:modelValue', 'reload'],
    inject: ['showDataTableProvide'],
    components: {
        Modal,
        Button,
        RadioButtons,
        Textbox
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        manCode: {
            type: Number,
            default: null
        },
        dataList: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        turnOffModal: function () {
            ;(this.isShowModal = false),
                this.$setGaEvent('turnOffModal', 'click-Button')
            setTimeout(() => {
                this.stepSeq = 1
            }, 800)
        },
        clickUploadInput: function () {
            this.$el.querySelector('#selectedLocalFile').click()
            this.$setGaEvent('selectedLocalFile', 'click-Button')
        },
        selectedLocalFile: function () {
            const files = event.target.files || event.dataTransfer.files || null
            if (!files[0]) return
            this.localFile = files[0]
            this.fileName = this.localFile.name
            const isValidSize = this.localFile.size <= 1572864 //畫面提醒1MB，後台放寬到1.5MB(1024*1024*1.5 = *1,572,864*)
            const isValidType =
                this.fileName.substring(this.fileName.lastIndexOf('.')) ===
                '.xlsx'
            if (!isValidSize && !isValidType)
                this.uploadError = `${this.uploadErrorList.size},${this.uploadErrorList.type}`
            else if (!isValidSize) this.uploadError = this.uploadErrorList.size
            else if (!isValidType) this.uploadError = this.uploadErrorList.type
            else this.uploadError = ''
        },
        uploadFile: async function () {
            if (!this.localFile || this.uploadError) return
            this.$setGaEvent('uploadFile', 'click-Button')
            let formData = new FormData()
            formData.append('file', this.localFile)

            try {
                this.stepSeq = 2
                this.isLoading = true
                this.showLongTimeLoadingMessage()
                let res = await uploadPropertyExcelAPI(formData)
                this.uploadResult = res.data.importResult
                this.isUploadSuccess = true
                this.uploadResult['fileName'] = this.fileName
                if (this.resultMessageList?.validCount > 0) {
                    this.showDataTableFn //編輯成功後重打保單列表API更新
                    this.$hideLoading()
                }
            } catch (error) {
                this.isUploadSuccess = false
                this.uploadResult = error?.response?.data ?? {}
            } finally {
                this.isLoading = false
                this.isShowModal = true
                this.stepSeq = 3
            }
        },
        getExcelUrl: async function () {
            if (this.insPickedCount === 0) {
                this.isNoPicked = true
                return
            }
            if (this.isDownloading) return
            const payload = {
                manCode: this.manCode,
                insMainCodes: this.propertyInsList
            }
            this.$setGaEvent(
                `get${this.$firstCapitalized(
                    this.filterRadioValue
                )}PropertyExcel`,
                'click-Button'
            )
            try {
                this.isLoading = true
                this.isDownloading = true
                this.showLongTimeLoadingMessage()
                let res = await downloadPropertyExcelAPI(payload)
                this.downloadUrls = res.data.urls
                this.hasDownloadError = false
                this.isLoading = false
                //須等beforeUnload事件被移除後再進行點擊連結動作
                setTimeout(() => {
                    this.downloadUrls.forEach((url, index) => {
                        const urlLink = document.createElement(`a`)
                        urlLink.setAttribute('href', url)
                        urlLink.setAttribute('class', `alink${index}`)
                        urlLink.click()
                    }, 50)
                })
            } catch (error) {
                this.hasDownloadError = true
                this.$hideLoading()
                this.$showErrorMessage(
                    '下載時發生錯誤，請稍後再試',
                    '如持續發生此情形，請聯絡資訊客服'
                )
            } finally {
                this.isDownloading = false
                this.isLoading = false
            }
        },
        clearUploadFile: function () {
            this.localFile = null
            this.fileName = '尚未選擇檔案'
            this.isNoPicked = false
        },
        showLongTimeLoadingMessage: function () {
            setTimeout(() => {
                if (this.isLoading) {
                    this.$setGaEvent(
                        `${this.stepSeq === 1 ? 'download' : 'upload'}TooLong`,
                        'load-File'
                    )
                    switch (this.stepSeq) {
                        case 1: {
                            this.isShowWaiteMessageModal = true
                            break
                        }
                        case 2: {
                            this.isShowWaiteMessage = true
                            break
                        }
                    }
                } else return
            }, 20000)
        },
        toggleBeforeunload: function () {
            if (this.isLoading) {
                window.addEventListener(
                    'beforeunload',
                    this.beforeunloadHandler,
                    false
                )
            } else {
                window.removeEventListener(
                    'beforeunload',
                    this.beforeunloadHandler,
                    false
                )
            }
        },
        beforeunloadHandler(e) {
            e.returnValue = ''
            e.preventDefault()
            return ''
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        downloadRemarks: function () {
            return `<p>1. 每個Excel至多100筆保單，超過會拆分成多個Excel壓縮成zip檔下載</p>
             <p>2. 編輯欄位格式（未依照以下格式輸入<span>不會</span>更新該欄位）：</p>
            <p>・車牌號碼（範例：ABC-1234），限制長度8碼含"-"</p>
            <p>・產險到期日（範例：2022-01-01）</p>
            `
        },
        uploadRemarks: function () {
            return `<p>檔案上傳限制：</p>
            <p>・檔案大小：1MB以內</p>
            <p>・檔案類型：.xlsx</p>
            `
        },
        uploadErrorList: function () {
            return {
                size: '超過檔案大小上限',
                type: '檔案類型錯誤'
            }
        },
        filterRadioOptions: function () {
            return [
                { label: '全部產險保單', name: 'propertyInsAll', value: 'all' },
                {
                    label: '生效日區間',
                    name: 'effectDateInterval',
                    value: 'interval'
                }
            ]
        },
        propertyInsList: function () {
            const dataList = _.cloneDeep(this.dataList)
            const radioValue = this.filterRadioValue
            const effectDateStart = new Date(this.effectDateStartValue)
            const effectDateEnd = new Date(this.effectDateEndValue)
            const hasStartDate = !isNaN(effectDateStart.getTime())
            const hasEndDate = !isNaN(effectDateEnd.getTime())

            if (!this.filterRadioValue) return []
            else
                return dataList
                    .filter(function (data) {
                        if (radioValue === 'interval') {
                            let isConformDate = false
                            const insEffectDate = new Date(data?.effectiveDate)

                            if (
                                effectDateStart <= insEffectDate &&
                                insEffectDate <= effectDateEnd
                            ) {
                                isConformDate = true
                            } else if (
                                !hasStartDate &&
                                insEffectDate <= effectDateEnd
                            ) {
                                isConformDate = true
                            } else if (
                                !hasEndDate &&
                                effectDateStart <= insEffectDate
                            ) {
                                isConformDate = true
                            }
                            return (
                                data?.jobTypeText?.includes('產險') &&
                                isConformDate
                            )
                        } else return data?.jobTypeText?.includes('產險')
                    })
                    .sort(
                        (a, b) =>
                            new Date(b?.effectiveDate) -
                            new Date(a?.effectiveDate)
                    )
                    .reduce((memo, data) => {
                        memo.push(parseInt(data?.mainCode))
                        return memo
                    }, [])
        },
        insPickedCount: function () {
            return this.propertyInsList?.length || 0
        },
        resultMessageList: function () {
            const uploadResult = this.uploadResult
            if (Object.keys(uploadResult).length > 0) {
                uploadResult.result = this.isUploadSuccess
                    ? '檔案已上傳'
                    : '檔案上傳失敗'
            }

            return uploadResult
        },
        showDataTableFn: function () {
            return this.showDataTableProvide()
        }
    },
    watch: {
        stepSeq: function (newVal) {
            this.$nextTick(() => {
                const modal = this.$el.querySelector('.modal-area')
                modal.scrollTo({
                    top: 0
                })
            })

            if (newVal === 1 || newVal === 3) {
                this.isShowWaiteMessage = false
            }
            if (newVal === 1) {
                this.clearUploadFile()
            }
            this.isNoPicked = false
        },
        modelValue: function (newVal) {
            if (!newVal) {
                this.$setGaEvent(`closeAtStep${this.stepSeq}`, 'close-Modal')
                this.clearUploadFile()
                if (this.stepSeq === 3) {
                    setTimeout(() => {
                        this.stepSeq = 1
                    }, 800)
                }
            }
        },
        isDownloading: function (newVal) {
            if (!newVal) {
                this.isShowWaiteMessageModal = false
            }
        },
        insPickedCount: function (newVal) {
            if (newVal > 0) {
                this.isNoPicked = false
            }
        },
        isLoading: function () {
            this.toggleBeforeunload()
        }
    },
    data() {
        return {
            isDownloading: false,
            isShowSuccessModal: false,
            isNoPicked: false,
            hasDownloadError: false,
            downloadUrls: [],
            localFile: null,
            fileName: '尚未選擇檔案',
            uploadError: '',
            filterRadioValue: 'interval',
            effectDateStartValue: this.$formatDate(
                this.$getFirstDate(this.$getDate())
            ),
            effectDateEndValue: this.$formatDate(this.$getDate()),
            stepSeq: 1,
            isUploadSuccess: null,
            uploadResult: {},
            isLoading: false,
            isShowWaiteMessageModal: false,
            isShowWaiteMessage: false
        }
    },
    unmounted() {
        this.isLoading = false
        this.toggleBeforeunload()
    }
}
</script>

<style lang="scss" scoped>
.second-title {
    font-size: 16px;
    color: $sixth-black;
    font-weight: bold;
    margin-bottom: 5px;
}
.depiction {
    font-size: 14px;
    margin-bottom: 20px;
    color: $sixth-black;
}

.download-container,
.upload-container {
    display: flex;
    flex-direction: column;
    .remark-container {
        padding: 10px;
        background: $primary-grey;
        :deep(p) {
            display: block;
            color: $sixth-black;
        }
        :deep(span) {
            color: $primary-red;
        }
        @media screen and (max-width: 576px) {
            margin-bottom: 0px;
        }
    }
}

.download-container {
    border-bottom: 1px dashed $fourth-grey;
    padding-bottom: 20px;
    a {
        width: fit-content;
    }
    .download-loading {
        background: $primary-white;
        :deep(.process-loading-icon) {
            width: 10px;
            height: 10px;
            order: 1;
            align-items: center;
        }
    }
}

:deep(.effect-date-inputs) {
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    .input-container {
        margin-bottom: 0;
    }
}

.file-selection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: $fourth-black-disabled;
        &.has-file {
            color: $sixth-black;
        }
        span {
            color: $primary-red;
            font-size: 12px;
        }
    }
}

.upload {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    word-break: break-all;
    margin-top: 20px;

    > :deep(button) {
        margin-bottom: 20px;
        margin-left: auto;
    }
    @media screen and (max-width: 576px) {
        flex-direction: column;
    }
    .file-selection {
        margin-bottom: 10px;
    }
}

.result-record {
    margin-right: auto;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    color: $fourth-blue;
    &:hover {
        color: $secondary-blue;
    }
}

//loading-content

.loading-container {
    display: block;
    // justify-content: center;
    width: 100%;
    padding: 20vh 0 0 0;
}

.process-loading-icon {
    box-sizing: border-box;
    border: 8px solid $third-grey;
    border-top: 8px solid $primary-blue;
    width: 50px;
    height: 50px;
    margin: auto;
}

.loading-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    color: $secondary-grey;
    text-align: center;
    margin: 20px 0;

    div {
        display: flex;
        flex-direction: column;
        .waiting-tip {
            margin: 0 auto;
            text-align: center;
            &:not(.warning) {
                margin-top: 20px;
            }
        }
    }
}

.uploading {
    :deep(.modal-area) {
        min-height: Min(100%, 726.7px);
    }
}

.waiting-tip {
    word-break: normal;
    font-size: 14px;
}
.warning {
    color: $primary-red;
}

//result-content
.result-status {
    font-size: 32px;
    font-weight: bold;
    &.success {
        color: $primary-green;
    }
    &.fail {
        color: $primary-red;
    }
    margin-bottom: 10px;
}

.status-description {
    margin-bottom: 10px;
    font-size: 15px;
    p {
        color: $sixth-black;
        font-size: 15px;
        word-break: keep-all;
    }
    span {
        word-break: break-all;
    }
}

.error-container {
    border-top: 1px dashed $fourth-grey;
    padding-top: 10px;
    margin-bottom: 10px;
    > p {
        margin-bottom: 10px;
        color: $sixth-black;
        font-size: 14px;
    }
    .error-message-container {
        background-color: $primary-grey;
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        border: 1px solid $primary-red;

        .error-message {
            &:not(:last-child) {
                margin-bottom: 15px;
            }

            p {
                color: $sixth-black;
                line-height: 16px;
                &.wrong-row {
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
